import Vue from 'vue'
import App from './App.vue'
import router from './router'

// import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'

// 导入全局样式表
import './assets/css/global.css'

import defines from './assets/js/defines.js'

// 导入字体图标(这里是阿里开源的字体图标库)
import './assets/fonts/iconfont.css'

import axios from 'axios'
// 导入nprogress
import NProgress from 'nprogress' // 导入js
// import 'nprogress/nprogress.css' // 导入样式表

// Vue.use(ElementUI)

Vue.config.productionTip = false

axios.defaults.baseURL = 'https://www.jepsonyang.com'

// 在request拦截器中显示进度条
axios.interceptors.request.use(config => {
  NProgress.start()

  // 往http头中加入Authorization字段
  const token = window.sessionStorage.getItem(defines.keyToken)
  if (token !== null) {
    config.headers.Authorization = token
  }

  return config
})

// 在response拦截器中隐藏进度条
axios.interceptors.response.use(config => {
  NProgress.done()

  // Token过期
  if (config.data.error_code === defines.EAuthExpire) {
    window.sessionStorage.clear() // 清空本地session
    router.push('/login') // 跳转到登陆页面

    if (config.config.url !== '/api/notebook/user/logout') { // 登出接口，不提示
      globalVue.$notify.error('Token已过期，请重新登录！！！')
    }

    // 抛出异常，避免调用方后序代码执行; 调用方可以通过捕获Promise的失败信息获取此Error
    throw new Error('token expired')
  }

  return config
})

Vue.prototype.$http = axios

const globalVue = new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
