import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home' // 访问根路径时，重定向到登陆页面
  },
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue')
  },
  {
    path: '/register',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Register.vue')
  },
  {
    path: '/home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
    redirect: '/home/welcome', // 重定向到'/welcome'路径
    children: [ // 子组件，即在Home组件中定义的router-view，才能使用
      {
        path: '/home/welcome',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Welcome.vue')
      },
      {
        path: '/home/test1',
        component: () => import(/* webpackChunkName: "test" */ '@/views/Test.vue')
      },
      {
        path: '/home/test2',
        component: () => import(/* webpackChunkName: "test" */ '@/views/Test.vue')
      },
      {
        path: '/home/test3',
        component: () => import(/* webpackChunkName: "test" */ '@/views/Test.vue')
      }
    ]
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "common" */ '@/views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 使用路由守卫，实现未登录时，直接访问页面会跳转到登陆页面
router.beforeEach((to, from, next) => {
  // 登陆页面，直接放行
  if (to.path === '/login' || to.path === '/register') {
    return next()
  }

  // 查看是否有token，如果没有，则跳转到登陆页面
  const token = window.sessionStorage.getItem('token')
  if (!token) {
    next('/login')
  }

  // 有token，则放行
  next()
})

export default router
