module.exports = {
  // Token相关字段
  keyToken: 'token',
  keyUserName: 'user_name',

  // 错误码
  ESuc: 0, // 成功
  EFailed: -1, // 失败
  EAuthExpire: -2, // 登录凭证过期
  EParamsInvalid: -3, // 请求参数非法
  EMarshalFailed: -4, // JSON解析失败
  EUnmarshalFailed: -5, // JSON序列化失败

  EUserExists: -100, // 用户已经存在(注册用户时)
  EUserNotExists: -101, // 用户名不存在
  EUserPasswordWrong: -102 // 用户密码错误
}
